import './Banner.css'

function Banner() {
    return (
        <>
            <img src="./imagens/banner.png" alt="rgs flag" className="banner" style={{ marginTop: "0px" }} />

        </>
    )
}

export default Banner; 