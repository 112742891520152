import { Link } from 'react-router-dom'

import { botaoVerde } from '../../estilo/estiloBotoes';

function BotaoVerde({link, texto}) {
    return (
        <>
            <Link to={link}>
                <button style={botaoVerde}>{texto}</button>
            </Link>
        </>
    )
}

export default BotaoVerde; 