import './Home.css'
import Banner from '../../componentes/Banner/Banner.js';
import Titulo from '../../componentes/Titulo/Titulo.js';
import Grid from '@mui/material/Grid';
import BotaoVerde from '../../componentes/BotaoVerde/BotaoVerde.js';

function Home() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Titulo />
        </Grid>
        <Grid item xs={12}>
          <Banner />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <BotaoVerde link='/doacao' texto='Doe Agora' />
            </Grid>
            <Grid item xs={3}>
              <BotaoVerde link='/contatos' texto='Contatos' />
            </Grid>
            <Grid item xs={3}>
              <BotaoVerde link='/noticias' texto='Notícias' />
            </Grid>
            <Grid item xs={3}>
              <BotaoVerde link='/abrigos' texto='Abrigos' />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div style={{ width: '100%', textAlign: 'center', color: "white", fontSize: "30px", marginBottom: '10px' }}>
            Acompanhe bloqueios nas rodovias em tempo real
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ width: '80%', height: '500px', marginBottom: '50px', margin: 'auto' }}>
            <iframe
              title='map'
              src="https://www.google.com/maps/d/embed?mid=1ZlKA__gK8tH-WY6mbDeQzltsiwao7Q8&hl=pt-BR"
              style={{ width: '100%', height: '100%', border: '0', alignContent: 'center' }}
              allowFullScreen=""
              loading="lazy">
            </iframe>
          </div>
        </Grid>
      </Grid>
      <footer>
        <div className="footer-phrase" style={{ fontSize: "24px" }}>
          Compilamos informações vitais de diversas fontes para apoiar o Rio Grande do Sul neste momento difícil
        </div>
      </footer>
    </>
  )
}

export default Home; 