import './Doacao.css'

import Titulo from '../../componentes/Titulo/Titulo';
import Grid from '@mui/material/Grid';


function Doacao() {
    const doacaoItemStyle = {
        width: '30em',
        height: '40em',
        marginBottom: '10%',
        border: 'solid 2px green'
    }

    const subtituloStyle = { color: "white", marginTop: "50px", marginLeft: "150px", textAlign: "center" }

    const doacoesLinks = [
        {
            "colunas": [
                {
                    href: 'https://sosenchentes.rs.gov.br/sobre-o-pix',
                    src: '/imagens/pixsos.jpeg'
                },
                {
                    href: 'https://sosenchentes.rs.gov.br/international-donations',
                    src: '/imagens/internacional.jpeg'
                },
            ]
        },
        {
            "colunas": [
                {
                    href: 'https://www.vakinha.com.br/vaquinha/a-maior-campanha-solidaria-do-rs?utm_internal_source=tags',
                    src: '/imagens/sosenchentes.jpeg'
                },
                {
                    href: 'https://saude.rs.gov.br/hemocentro-solicita-doacoes-de-sangue-tipo-o-ou-com-fator-rh-negativo',
                    src: '/imagens/sangue.jpeg'
                },
            ]
        },
        {
            "colunas": [
                {
                    href: 'https://www.instagram.com/acheseupetrs/?igsh=MTl5bnM5dTZkaWlzaA%3D%3D',
                    src: '/imagens/pet.jpeg'
                },
                {
                    href: 'https://www.instagram.com/cfchumaitafutevolei/?igsh=ejIwbDBheDcydGph',
                    src: '/imagens/refecoes.jpeg'
                },
            ]
        },
        {
            "colunas": [
                {
                    href: 'https://saladeimprensa.correios.com.br/arquivos/9567',
                    src: '/imagens/correios.png'
                },
                {
                    href: 'https://www.instagram.com/prefeituradealvorada/?igsh=N3lpZHhncGFoOXNp',
                    src: '/imagens/prefeitura.jpeg'
                },
            ]
        },
    ]
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Titulo />
                </Grid>
                <Grid item xs={12}>
                    <h1 style={subtituloStyle}>
                        Para mais informações, acesse o site oficial do governo do Rio Grande do Sul
                    </h1>
                    <h3 style={subtituloStyle}>
                        Clique nas imagens abaixo para ser redirecionado diretamente aos sites oficiais
                    </h3>
                </Grid>
                {
                    doacoesLinks.map(linha => (
                        linha.colunas.map(item => (
                            <Grid item xs={6}>
                                <div style={{ backgroundColor: '', textAlign: 'center' }}>
                                    <a href={item.href}>
                                        <img src={item.src} alt="agorars" style={doacaoItemStyle} />
                                    </a>
                                </div>
                            </Grid>
                        ))
                    ))
                }
                <Grid item xs={12}>
                    <a href="https://sosenchentes.rs.gov.br/doacoes-em-geral"
                        style={{
                            color: "inherit",
                            textDecoration: "none"
                        }}>
                        <h2 style={{ fontSize: '32px', color: "aqua", marginTop: "50px", textAlign: 'center'}}>PARA DOAÇÕES EM GERAL CLIQUE AQUI </h2>
                    </a>
                </Grid>
            </Grid>
        </>
    )
}

export default Doacao; 