import './Titulo.css'
import { Link } from 'react-router-dom'

function Titulo() {
    return (
        <>
            <div className="header" style={{ fontSize: '36px', marginTop: "30px", position: 'relative', zIndex: 1000 }}>
                <Link to="/" style={{ color: 'yellow', textDecoration: 'none' }}>
                    SOS Rio Grande do Sul
                </Link>
            </div>
        </>
    )
}

export default Titulo; 