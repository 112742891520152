import "./Abrigos.css";
import Titulo from "../../componentes/Titulo/Titulo";
import { Grid } from "@mui/material";

function Abrigos() {
  const imageTelefonesStyle = {
    height: "900px",
    width: "500px",
    margin: "10px",
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Titulo />
        </Grid>
        <Grid item xs={12}>
          <h2
            style={{
              color: "white",
              fontSize: "26px",
              textAlign: "center",
            }}
          >
            Clique na imagem abaixo para acessar em tempo real a disponibilidade
            de abrigos
          </h2>
        </Grid>
      </Grid>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <a href="https://linktr.ee/sosrs_ajuda" style={{}}>
          <img src="./imagens/sosajuda.png" alt="ajuda" />
        </a>
      </div>

      <h2
        style={{
          color: "white",
          fontSize: "32px",
          textAlign: "center",
        }}
      >
        Lista de abrigos informados à Defesa Civil
      </h2>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="./imagens/Captura1.png"
          style={imageTelefonesStyle}
          alt="Captura1"
        />
        <img
          src="./imagens/Captura2.png"
          style={imageTelefonesStyle}
          alt="Captura2"
        />
        <img
          src="./imagens/Captura3.png"
          style={imageTelefonesStyle}
          alt="Captura2"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="./imagens/Captura4.png"
          style={imageTelefonesStyle}
          alt="Captura1"
        />
        <img
          src="./imagens/Captura5.png"
          style={imageTelefonesStyle}
          alt="Captura2"
        />
        <img
          src="./imagens/Captura6.png"
          style={imageTelefonesStyle}
          alt="Captura2"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="./imagens/Captura7.png"
          style={imageTelefonesStyle}
          alt="Captura1"
        />
        <img
          src="./imagens/Captura8.png"
          style={imageTelefonesStyle}
          alt="Captura2"
        />
        <img
          src="./imagens/Captura9.png"
          style={imageTelefonesStyle}
          alt="Captura2"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="./imagens/Captura10.png"
          style={imageTelefonesStyle}
          alt="Captura1"
        />
        <img
          src="./imagens/Captura12.png"
          style={imageTelefonesStyle}
          alt="Captura2"
        />
        <img
          src="./imagens/Captura11.png"
          style={imageTelefonesStyle}
          alt="Captura2"
        />
      </div>

      <h4
        style={{
          color: "aqua",
          textAlign: "center",
        }}
      >
        <a
          href="https://sosenchentes.rs.gov.br/upload/arquivos/202405/10131409-abrigos-e-escolas-10-5-2024.pdf"
          style={{
            color: "inherit",
            textDecoration: "none",
            fontSize: "25px",
          }}
        >
          https://sosenchentes.rs.gov.br/upload/arquivos/202405/10131409-abrigos-e-escolas-10-5-2024.pdf
        </a>
      </h4>
    </>
  );
}

export default Abrigos;
