import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom"

import Doacao from './Pages/Doacao/Doacao.js';
import Contatos from './Pages/Contatos/Contatos.js';
import Noticias from './Pages/Noticias/Noticias.js';
import Abrigos from './Pages/Abrigos/Abrigos.js';
import Home from './Pages/Home/Home.js';


function App() {
  return (
    <>
        <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/doacao" element={<Doacao />}></Route>
            <Route path="/contatos" element={<Contatos />}></Route>
            <Route path="/noticias" element={<Noticias />}></Route>
            <Route path="/abrigos" element={<Abrigos />}></Route>
        </Routes>
        </BrowserRouter>
    </>

  );
}

export default App;
