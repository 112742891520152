export const botaoVerde = 
{
    display: 'block',
    margin: 'auto',
    padding: '10px 20px',
    backgroundColor: '#08520a',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '80%',
    fontSize: '16px',
}