import "./Noticias.css";
import Titulo from "../../componentes/Titulo/Titulo";
import Grid from "@mui/material/Grid";

const jornaisDeRs = [
  {
    href: "https://agorars.com/",
    src: "/imagens/agorars.png",
  },
  {
    href: "https://www.osul.com.br/",
    src: "/imagens/osul.png",
  },
  {
    href: "https://www.correiodopovo.com.br/",
    src: "/imagens/correiodopovo.png",
  },
  {
    href: "https://gauchazh.clicrbs.com.br/",
    src: "/imagens/gauchazh.png",
  },
  {
    href: "https://diariogaucho.clicrbs.com.br/",
    src: "/imagens/diariogaucho.png",
  },
  {
    href: "https://www.jornaldopovo.net/",
    src: "/imagens/jornaldopovo.png",
  },
  {
    href: "https://diariopopular.com.br/",
    src: "/imagens/diariopopular.png",
  },
  {
    href: "https://www.onacional.com.br/",
    src: "/imagens/onacional.png",
  },
  {
    href: "https://odiario.net/",
    src: "/imagens/odiario.png",
  },
  {
    href: "https://www.jornaldocomercio.com/",
    src: "/imagens/jornaldocomercio.png",
  },
  {
    href: "https://www.gaz.com.br/",
    src: "/imagens/gaz.png",
  },
  {
    href: "https://www.abcmais.com/",
    src: "/imagens/abc.png",
  },
  {
    href: "https://www.rs.gov.br/inicial",
    src: "/imagens/governodoestado.png",
  },
  {
    href: "https://diariosm.com.br/",
    src: "/imagens/diario.png",
  },
];

function Noticias() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Titulo />
        </Grid>
        <Grid item xs={12}>
          <h2
            style={{
              fontSize: "24px",
              color: "white",
              textAlign: "center",
            }}
          >
            Visite os jornais da região do Rio Grande do Sul clicando nas
            imagens abaixo
          </h2>
        </Grid>
        {jornaisDeRs.map((jornal) => (
          <Grid item xs={2} style={{
          display: 'flex',        /* Enables flexbox layout */
          justifyContent: 'center', /* Centers content along the main axis (horizontally) */
          alignItems: 'center',    /* Optional: Centers content along the cross axis (vertically) */
        //   backgroundColor: 'pink'
          }}>
            <a
              href={jornal.href}
            >
              <img
                src={jornal.src}
                alt={jornal.href}
                style={{ 
                    width: "400px", 
                    // border: 'solid 2px black'
                }}
              />
            </a>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default Noticias;
