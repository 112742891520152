import "./Contatos.css";
import Titulo from "../../componentes/Titulo/Titulo";
import Grid from "@mui/material/Grid";

function Contatos() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Titulo />
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              width: "100%",
              height: "200px",
              overflow: "hidden",
              textAlign: "center",
            }}
          >
            <img
              src="./imagens/Disque100.png"
              alt="contato1"
              style={{
                marginTop: "-20px",
                marginRight: "-80px",
                marginLeft: "-100px",
                width: "400px",
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <h2 style={{ color: "white", fontSize: "20px", textAlign: "center" }}>
            O Disque 100 abriu um canal para receber informações sobre crianças
            e adolescentes desaparecidos ou desacompanhados
          </h2>
        </Grid>
        <Grid item xs={12}>
          <h4
            style={{
              color: "aqua",
              marginTop: "60px",
              textAlign: "center",
            }}
          >
            <a
              href="https://agenciabrasil.ebc.com.br/geral/noticia/2024-05/disque-100-abre-novo-canal-para-localizar-criancas-desaparecidas-no-rs"
              style={{
                color: "inherit", // Herda a cor do elemento pai
                textDecoration: "none", // Remove o sublinhado do link
                fontSize: "36px", // Aumenta o tamanho da fonte para 20 pixels
              }}
            >
              Agência Brasil
            </a>
          </h4>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              width: "100%",
              overflow: "hidden",
              textAlign: "center",
            }}
          >
            <img src="./imagens/contatosextras.png" alt="contato1" />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              width: "100%",
              height: "1600px",
              overflow: "hidden",
              textAlign: "center",
            }}
          >
            <img
              src="./imagens/contato1.png"
              alt="contato1"
              style={{
                marginTop: "-150px",
                width: "80%",
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              width: "100%",
              height: "1650px",
              overflow: "hidden",
              textAlign: "center",
            }}
          >
            <img
              src="./imagens/contato2.png"
              alt="contato2"
              style={{
                marginTop: "-150px",
                width: "80%",
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              width: "100%",
              height: "1650px",
              overflow: "hidden",
              textAlign: "center",
            }}
          >
            <img
              src="./imagens/contato3.png"
              alt="contato3"
              style={{
                marginLeft: '8%',
                width: "80%",
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <h4
            style={{
              color: "aqua",
              fontSize: "20px",
              textAlign: "center",
            }}
          >
            <a
              href="https://www.estado.rs.gov.br/upload/arquivos/202405/telefones-de-emergencia-brigada-e-bombeiros.pdf"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              https://www.estado.rs.gov.br/upload/arquivos/202405/telefones-de-emergencia-brigada-e-bombeiros.pdf
            </a>
          </h4>
        </Grid>
      </Grid>
    </>
  );
}

export default Contatos;
